import { useForm } from "react-hook-form";
import { useUpdateUserSearchMutation } from "../../../../../services/userEndpoints";
import { toast } from "react-toastify";

/**
 * Custom hook for managing save search form functionality.
 * @param {number} userId - The id of the current logged in user.
 * @param {function} toggle - The toggle for the form drawer open/close.
 * @param {object} saveSearchData - The data attributed to the saved search being updated.
 * @returns An object containing the functions and data needed for the save search form.
 */
export const useSaveSearchForm = ({ userId, toggle, saveSearchData }) => {
    const [updateSearch, searchStatus] = useUpdateUserSearchMutation();
    const { filters, nicheItem, emailAlert, id } = saveSearchData || {};
    const searchForm = useForm({
        mode: "onBlur",
        defaultValues: {
            ...filters,
            alertType: emailAlert,
        },
    });
    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = searchForm;
    const { ref, ...restOfForm } = register("alertType");
    const handleUpdate = async (data) => {
        try {
            await updateSearch({
                userId,
                id,
                body: data,
            }).unwrap();
            toast.success("Search updated!");
            toggle();
        } catch (error) {
            toast.error(
                "Sorry, we were unable to update your search. Please try again later and if the issue continues contact support."
            );
        }
    };

    return {
        isLoading: searchStatus?.isLoading,
        handleSubmit,
        isValid,
        restOfForm,
        handleUpdate,
        register,
        searchForm,
        ref,
        filters,
        nicheItem,
    };
};
