import React from "react";
import Bedrooms from "../../Bedrooms";
import { useSearchQueryParam } from "../../../../hooks/useSearchQueryParam";

/**
 * The Bedrooms search component.
 * @param {boolean} isMobile - Determines whether the device is mobile or not.
 * @param {function} toggleIsOpen - The function that gets called to toggle open/shut.
 * @returns The Bedrooms component that is used in the Horizontal Search.
 */
const BedroomsSearch = ({ className, isMobile = false, toggleIsOpen }) => {
    const [bedrooms, setBedrooms] = useSearchQueryParam("bedrooms");
    const handleSetBedrooms = (value) => {
        if (value > 0) {
            setBedrooms(value);
        } else {
            setBedrooms();
        }
    };

    return (
        <Bedrooms
            className={className}
            isMobile={isMobile}
            toggleIsOpen={toggleIsOpen}
            bedrooms={bedrooms}
            handleSetBedrooms={handleSetBedrooms}
        />
    );
};
export default BedroomsSearch;
