import React from "react";
import Bathrooms from "../../Bathrooms";
import { useSearchQueryParam } from "../../../../hooks/useSearchQueryParam";

/**
 * The Bathrooms search component.
 * @param {boolean} isMobile - Determines whether the device is mobile or not.
 * @param {function} toggleIsOpen - The function that gets called to toggle open/shut.
 * @returns The Bathrooms component that is used in the Horizontal Search.
 */
const BathroomsSearch = ({ className, isMobile = false, toggleIsOpen }) => {
    const [bathrooms, setBathrooms] = useSearchQueryParam("bathrooms");
    const handleSetBathrooms = (value) => {
        if (value > 0) {
            setBathrooms(value);
        } else {
            setBathrooms();
        }
    };

    return (
        <Bathrooms
            className={className}
            isMobile={isMobile}
            toggleIsOpen={toggleIsOpen}
            bathrooms={bathrooms}
            handleSetBathrooms={handleSetBathrooms}
        />
    );
};
export default BathroomsSearch;
