import React from "react";
import PriceRangeInput from "./PriceRangeInput";

const PriceRange = ({
    label,
    minPrice,
    maxPrice,
    handleSetMinPrice,
    handleSetMaxPrice,
}) => {
    return (
        <div className="d-flex align-items-center justify-content-between">
            {label && <div className="text-muted mx-2">{label}</div>}
            <PriceRangeInput
                value={minPrice}
                setValue={handleSetMinPrice}
                defaultOptions={[0, 100000, 200000, 300000, 400000, 500000]}
                label="Min"
                suffix="+"
            />
            <div className="text-muted mx-2">to</div>
            <PriceRangeInput
                value={maxPrice}
                setValue={handleSetMaxPrice}
                defaultOptions={[
                    300000,
                    400000,
                    500000,
                    600000,
                    700000,
                    800000,
                    900000,
                    1000000,
                    "Any",
                ]}
                label="Max"
            />
        </div>
    );
};

export default PriceRange;
