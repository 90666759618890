import React from "react";
import AssemblyProvider from "../../../AssemblyProvider";
import IndeterminateButton from "../../../IndeterminateButton";
import ValidatingField from "../../../ValidatingField";
import { withRouter } from "react-router";
import { Label, FormGroup, Input, Form, Alert, Row, Col } from "reactstrap";
import { useSaveSearchForm } from "./hooks/useSaveSearchForm";
import { generateSearchName } from "../../../../support/helpers";

/**
 * Save Search form component.
 * @param {number} userId - The id of the current logged in user.
 * @param {function} toggle - The toggle for the form drawer open/close.
 * @param {object} saveSearchData - The data attributed to the saved search being updated.
 * @param {object} alertOptions - An array of alert frequency options for the user to choose from.
 * @returns The initial form to edit search name and alert frequency when a user saves a new search.
 */
const SaveSearchForm = ({ userId, toggle, saveSearchData, alertOptions }) => {
    const {
        isLoading,
        handleSubmit,
        isValid,
        restOfForm,
        handleUpdate,
        register,
        searchForm,
        ref,
        filters,
        nicheItem,
    } = useSaveSearchForm({ userId, toggle, saveSearchData });

    return (
        <div>
            {saveSearchData && (
                <AssemblyProvider group="USER_SEARCH" name="USER_SEARCH">
                    {(assembly) => (
                        <Form onSubmit={handleSubmit(handleUpdate)}>
                            <div className="border-bottom pb-2 mb-2 text-center h4">
                                Search Saved!
                            </div>
                            <Label>
                                Use the following form to tailor your saved
                                search.
                            </Label>
                            <FormGroup>
                                <Label>Search Name</Label>
                                <ValidatingField
                                    hideLabel
                                    {...register("name")}
                                    assembly={assembly}
                                    {...searchForm}
                                    placeholder={generateSearchName(
                                        filters,
                                        nicheItem
                                    )}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>Alert Frequency</Label>
                                <Input
                                    type="select"
                                    className="px-2"
                                    data-testid="alert-dropdown"
                                    innerRef={ref}
                                    {...restOfForm}
                                >
                                    {Object.keys(alertOptions).map((key, i) => (
                                        <option
                                            key={`option-${i}`}
                                            data-testid={`option-${i}`}
                                            value={alertOptions[key]?.value}
                                        >
                                            {alertOptions[key]?.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <Row className="align-items-center mt-3">
                                <Col className="d-flex">
                                    <FormGroup className="ms-auto">
                                        <IndeterminateButton
                                            type="submit"
                                            className="btn btn-primary"
                                            isLoading={isLoading}
                                        >
                                            Update Search
                                        </IndeterminateButton>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </AssemblyProvider>
            )}
        </div>
    );
};

export default withRouter(SaveSearchForm);
