import React from "react";
import { Button } from "reactstrap";
import ToggleButton from "./ToggleButton";

const Waterfront = ({
    toggleIsOpen,
    isToggle,
    toggleClassName,
    isWaterfront,
    handleSetIsWaterfront,
}) => {
    return (
        <>
            {isToggle ? (
                <ToggleButton
                    className={toggleClassName}
                    value={isWaterfront}
                    onToggle={() =>
                        handleSetIsWaterfront(!isWaterfront ? 1 : null)
                    }
                />
            ) : (
                <>
                    <Button
                        className="d-block w-100 my-1"
                        color={isWaterfront ? "light" : "primary"}
                        onClick={() => {
                            handleSetIsWaterfront();
                            toggleIsOpen();
                        }}
                    >
                        All
                    </Button>
                    <Button
                        className="d-block w-100 my-1"
                        color={isWaterfront ? "primary" : "light"}
                        onClick={() => {
                            handleSetIsWaterfront(1);
                            toggleIsOpen();
                        }}
                    >
                        Tagged as Waterfront
                    </Button>
                </>
            )}
        </>
    );
};

export default Waterfront;
