import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useQueryParam, NumberParam } from "use-query-params";

const Pagnation = ({ page_count, page_number }) => {
    const [page, setPage] = useQueryParam("page", NumberParam);
    const { pathname, search } = useLocation();

    return (
        <StyledPagnation
            className="row g-0 justify-content-center"
            data-test="listings-pagination"
        >
            <ReactPaginate
                pageCount={page_count}
                forcePage={page_number - 1}
                pageRangeDisplayed={1}
                marginPagesDisplayed={2}
                previousLabel={<FaChevronLeft style={{ marginLeft: "-2px" }} />}
                nextLabel={<FaChevronRight style={{ marginRight: "-2px" }} />}
                containerClassName="list-inline d-flex justify-content-center align-items-center"
                pageClassName="d-inline text-primary mx-1"
                breakClassName="d-inline text-primary mx-1"
                activeClassName="d-flex justify-content-center align-items-center border-primary text-primary p-2 selected text-decoration-underline"
                activeLinkClassName=""
                previousClassName="d-inline me-1 me-md-4"
                previousLinkClassName="d-flex justify-content-center align-items-center border rounded-circle bg-light border-primary text-primary p-2"
                nextClassName="d-inline ms-1 ms-md-4"
                nextLinkClassName="d-flex justify-content-center align-items-center border rounded-circle bg-light border-primary text-primary p-2"
                disabledClassName="disabled"
                hrefBuilder={(currentPage) => {
                    const urlParams = new URLSearchParams(search);
                    urlParams.set("page", currentPage);
                    return `${pathname}?${urlParams.toString()}`;
                }}
                onPageChange={async ({ selected }) => {
                    window.scrollTo(0, 0);
                    const nextPage = selected + 1;
                    setPage(nextPage);
                }}
            />
        </StyledPagnation>
    );
};

const StyledPagnation = styled.div`
    .selected a {
        font-weight: bold;
        text-align: center;
        font-size: 20px;
    }
    li:not(.selected) {
        a {
            cursor: pointer;
        }
        a:hover {
            text-decoration: underline;
        }
    }
    li.selected a,
    li.disabled a,
    li.disabled span,
    li.disabled svg {
        cursor: auto;
    }
    .disabled .text-primary,
    .disabled .border-primary {
        color: var(--bs-light-gray) !important;
        border-color: var(--bs-light-gray) !important;
    }
`;

export default Pagnation;
