import React from "react";
import SelectorButtons from "./SelectorButtons";
import { useTrackEvent } from "../../hooks/useTrackEvent";

const Bedrooms = ({
    className,
    isMobile = false,
    toggleIsOpen,
    bedrooms,
    handleSetBedrooms,
}) => {
    const { trackGTM } = useTrackEvent();

    return (
        <SelectorButtons
            className={className}
            buttonStyle={{ minWidth: isMobile ? "40px" : "50px" }}
            items={[
                { label: "Any", value: 0 },
                { label: "1+", value: 1 },
                { label: "2+", value: 2 },
                { label: "3+", value: 3 },
                { label: "4+", value: 4 },
                { label: "5+", value: 5 },
            ]}
            selected={bedrooms}
            callback={(value) => {
                handleSetBedrooms(value);
                trackGTM({
                    event: `userSearch`,
                    action: "filter",
                    type: "bedrooms",
                    category: "user_action",
                    value: value > 0 ? value : "",
                });
                if (toggleIsOpen) toggleIsOpen();
            }}
        />
    );
};
export default Bedrooms;
