import React from "react";
import ListButtons from "./ListButtons";
import { useTrackEvent } from "../../hooks/useTrackEvent";

const PropertyType = ({
    toggleIsOpen,
    propertyType,
    handleSetPropertyType,
}) => {
    const { trackGTM } = useTrackEvent();

    return (
        <ListButtons
            itemName="property_type"
            setValue={(value, options) => {
                handleSetPropertyType(value, options);
                trackGTM({
                    event: `userSearch`,
                    action: "filter",
                    type: "property_type",
                    category: "user_action",
                    value: value !== options[0].value ? value : "",
                });
            }}
            currentValue={propertyType}
            callback={() => toggleIsOpen && toggleIsOpen()}
        />
    );
};

export default PropertyType;
